/* eslint-disable react/no-danger */
import React, { useEffect, useContext } from 'react';
import Helmet from 'react-helmet';
import { FeaturesSlider } from '@latitude/features-slider';
import { Link } from '@latitude/link';
import { Hero } from '@latitude/hero';
import Layout from '../../components/layout';
import {
  BREAKPOINT,
  COLOR,
  BUTTON_STYLE,
  PADDING
} from '@latitude/core/utils/constants';
import heroImage from './images/scam-awareness-hero.webp';
import { HeroText } from '@/components/HeroBranded/HeroBranded';
import Section from '@latitude/section';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { useSetState } from '@/utils/hooks';
import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import PageData from '../../data/pages/scam-tips.json';

const ScamsTipsPage = props => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};

  const featureSliderData = [
    {
      heading: 'Common scam red flags',
      featureCards: PageData.content.scamRedFlags
    },
    {
      heading: 'Top Tips to protect yourself',
      featureCards: PageData.content.topTips1
    },
    {
      featureCards: PageData.content.topTips2
    }
  ];
  /** - END - */
  return (
    <Layout location={props.location}>
      <MobileAppInstallPrompts />
      <main
        className="navigation-spacer"
        css={`
          background-color: ${COLOR.GREY6};
        `}
      >
        <Helmet>
          <link
            rel="canonical"
            href={`https://www.latitudefinancial.com.au${PageData.path}`}
          />
          <title>{PageData.metaTitle}</title>
          <meta name="description" content={PageData.metaDesc} />
        </Helmet>
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <Section
            css={`
              background-color: #0555c8;
              padding: 0;
              @media (min-width: ${BREAKPOINT.LG}) {
                height: 525px;
              }

              @media (max-width: ${BREAKPOINT.MD}) {
                && > .container.section__content {
                  padding: 0;
                }
              }
            `}
          >
            <Hero
              css={`
                && {
                  color: #000;
                }

                h1 {
                  color: #fff;
                  font-size: 45px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 3rem;
                }

                p {
                  color: #fff;
                  font-size: 19px;
                  font-weight: normal;
                }

                svg {
                  min-height: 45px;
                  min-width: 45px;
                }

                .HeroContent {
                  padding: 50px 60px 0 10px;
                  line-height: normal;
                  @media (max-width: ${BREAKPOINT.MD}) {
                    background: transparent;
                    h1,
                    p {
                      color: #fff !important;
                    }
                  }
                }

                [class^='Herostyles__HeroImage-'] img {
                  height: 95%;
                }

                @media (max-width: ${BREAKPOINT.MD}) {
                  h1,
                  .text-left,
                  p,
                  .col-12 {
                    color: #000 !important;
                  }

                  p {
                    font-size: 20px;
                    line-height: 24px;
                  }

                  h1 {
                    font-size: 35px;
                    margin: 10px 0;
                  }

                  .HeroContent {
                    padding: 10px;
                  }

                  .text-center {
                    color: #000 !important;
                  }
                }
              `}
              imageContent={
                <div
                  css={`
                    height: 100%;
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    @media (min-width: ${BREAKPOINT.LG}) {
                      justify-content: flex-end;
                    }
                  `}
                >
                  <img
                    src={heroImage}
                    alt=""
                    css={`
                      height: 250px;
                      width: auto;
                      @media (min-width: ${BREAKPOINT.MD}) {
                        height: 300px;
                      }
                      @media (min-width: ${BREAKPOINT.LG}) {
                        height: 382px;
                      }
                    `}
                  />
                </div>
              }
              className="page-banner-lfs--bg"
            >
              {
                <div
                  id="hero"
                  css={`
                && {
                  padding: 0 15px;
                  color: #fff:

                  @media (min-width: ${BREAKPOINT.LG}) {
                    padding: 0;
                  }
                }
              `}
                >
                  <h1>Share a story, stop a scam </h1>
                  <HeroText
                    css={`
                      && {
                        color: #fff;
                      }
                    `}
                  >
                    The theme for this year’s Scam Awareness Week is 'share a
                    story, stop a scam.' By sharing our stories and experiences
                    with each other, we help the community be aware of scams and
                    how to avoid them. 
                  </HeroText>
                </div>
              }
            </Hero>
          </Section>
        )}

        <Section
          css={`
            h4 {
              color: #0046aa;
            }
          `}
        >
          <FeaturesSlider
            id="why-us"
            key={
              (state?.featureSliderData?.[0] || featureSliderData[0]).heading
            }
            data={
              (state?.featureSliderData?.[0] || featureSliderData[0])
                .featureCards
            }
            className="why-choose pt-4 pt-md-5"
            heading={
              (state?.featureSliderData?.[0] || featureSliderData[0]).heading
            }
            subheading={
              (state?.featureSliderData?.[0] || featureSliderData[0])
                .description
            }
          />
        </Section>
        <Section
          css={`
            background: #0555c8;
            * {
              color: #fff;
            }
          `}
        >
          <FeaturesSlider
            id="top-tips-part1"
            data={
              (state?.featureSliderData?.[1] || featureSliderData[1])
                .featureCards
            }
            className="op-tips pt-4 pt-md-5"
            heading={
              (state?.featureSliderData?.[1] || featureSliderData[1]).heading
            }
            subheading={
              (state?.featureSliderData?.[1] || featureSliderData[1])
                .description
            }
          />

          <FeaturesSlider
            id="top-tips-part2"
            data={
              (state?.featureSliderData?.[2] || featureSliderData[2])
                .featureCards
            }
            className="top-tips pt-4 pt-md-5"
            heading={
              (state?.featureSliderData?.[2] || featureSliderData[2]).heading
            }
            subheading={
              (state?.featureSliderData?.[2] || featureSliderData[2])
                .description
            }
          />
        </Section>

        <Section
          class="more-support"
          css={`
            text-align: center;
          `}
        >
          <h4
            css={`
              color: #0046aa;
              font-size: 30px !important;
            `}
          >
            {' '}
            More support when you need it
          </h4>
          <p>
            Visit{' '}
            <Link
              href="https://www.latitudefinancial.com.au/security/how-we-protect-you/"
              target="_blank"
            >
              Latitude Security page
            </Link>{' '}
            to learn more about how we protect you.
          </p>
          <p>
            Visit the{' '}
            <Link href="https://www.scamwatch.gov.au/" target="_blank">
              Scamwatch website
            </Link>{' '}
            for further information on scams, fraud and cyber threats.
          </p>
          <p>
            Contact{' '}
            <Link href="https://www.idcare.org/" target="_blank">
              IDCARE,
            </Link>{' '}
            an independent organisation providing free support to individuals
            impacted by fraud and scams by calling
            <Link href="#" target="_blank">
              {' '}
              1800 595 160
            </Link>
            .
          </p>
          <p>The Latitude Fraud Team </p>
        </Section>
      </main>
    </Layout>
  );
};



export default ScamsTipsPage;
